import './App.css';
import {Badge} from './components/badge';
import {Input} from './components/input';
import { Field, Label } from './components/fieldset'
import { Button } from './components/button'
import './codeFormat.css';
import './selectorFormat.css'
import './windowButtons.css'
import './purpleInput.css'
import React, { useState } from 'react';
import Editor from '@monaco-editor/react';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { HiOutlineSparkles } from "react-icons/hi2";
import { FiDownload } from "react-icons/fi";
import axios from 'axios'; 
import svg from './svg.svg'
import Skeleton from '@mui/material/Skeleton';
import Lottie from "lottie-react";
import codeAnimation from './code.json'
import creditCard from './credit-card.json'
import stars from './Stars.json'

function App() {
//   const [files, setFiles] = useState({
//     "index.html": "<html>\n<head>\n<style>\nbody { color: red; }\n</style>\n</head>\n<body>\nHello World!\n</body>\n</html>",
//     "style.css": "body { font-family: Arial; }",
//     "script.js": "console.log('Hello World');",
// });
const [files, setFiles] = useState({

});
const [sampleEndpoints, setSampleEndpoints] = useState({});
const [selectedFile, setSelectedFile] = useState("index.html");
const [folderName, setFolderName] = useState("ProjectFiles");
const [userPrompt, setUserPrompt] = useState("");
const [loading, setLoading] = useState(false);
const [disabled, setDisabled] = useState(true);
const [frontEndRequest, setFrontEndRequest] = useState(false);
const [backEndRequest, setBackEndRequest] = useState(false);
const [requestType, setRequestType] = useState("");
const [iframeContent, setIframeContent] = useState("");

const handleFileSwitch = (fileName) => {
    setSelectedFile(fileName);
};

const handleEditorChange = (value) => {
    setFiles((prevFiles) => ({
        ...prevFiles,
        [selectedFile]: value,
    }));
};

const handleSave = () => {
  const zip = new JSZip();

  for (const [fileName, content] of Object.entries(files)) {
      zip.file(fileName, content);
  }

  zip.generateAsync({ type: 'blob' })
      .then((content) => {
          saveAs(content, `${folderName}.zip`);
      });
};

const combineFiles = (files) => {
  let { Html, Css, Js } = files;

  // Remove leading and trailing newline characters
  Html = Html.trim();
  Css = Css.trim();
  Js = Js.trim();

  // Create a new HTML document
  const parser = new DOMParser();
  const doc = parser.parseFromString(Html, 'text/html');

  // Create a new <style> element and set its content
  const styleElement = doc.createElement('style');
  styleElement.textContent = Css;

  // Remove the existing <link> element for the stylesheet
  const existingStyleLink = doc.querySelector('link[rel="stylesheet"]');
  if (existingStyleLink) {
    existingStyleLink.remove();
  }

  // Append the new <style> element to the <head>
  doc.head.appendChild(styleElement);

  // Create a new <script> element and set its content
  const scriptElement = doc.createElement('script');
  scriptElement.textContent = Js;

  // Remove the existing <script> element
  const existingScriptElement = doc.querySelector('script');
  if (existingScriptElement) {
    existingScriptElement.remove();
  }

  // Append the new <script> element to the end of the <body>
  doc.body.appendChild(scriptElement);

  // Get the combined HTML string
  const combinedHtml = doc.documentElement.outerHTML;
// console.log(combinedHtml)
  return combinedHtml;
}

const handlePromptChange = (e) => setUserPrompt(e.target.value);

  const handleGenerate = async () => {
    setLoading(true);
    try {
      // const response = await axios.post("http://127.0.0.1:5000/query_stacked_prompts_gemini",
      const response = await axios.post("https://softwarenow-ai.uc.r.appspot.com/query_stacked_prompts_gemini",
       {
        prompt: userPrompt,
      });

      const  responseData  = response.data;
// console.log(responseData)
      if (responseData) {
        const responseType = responseData.response_type;
        setRequestType(responseType);

        if (responseType === "FrontEnd") {
          const codeFiles = responseData.frontend_files;
          setFiles({
            "index.html": codeFiles["Html"] || "",
            "style.css": codeFiles["Css"] || "",
            "script.js": codeFiles["Js"] || "", 
          });
          setIframeContent(combineFiles(codeFiles));  
          setDisabled(false);
        } else if (responseType === "BackEnd") {
          const backendCode = responseData.backend_files;
          setFiles({
            "main.py": backendCode["main.py"] || "",
            "requirements.txt": backendCode["requirements.txt"] || "",
           });
           const endpoints = parseEndpoints(backendCode["main.py"]);
           setSelectedFile("main.py");
          //  console.log(endpoints);
           setSampleEndpoints(endpoints);
           setDisabled(false);
        } else if (responseType === "Neither") {
          console.error("Please submit a code request!");
        } else {
          console.error("Error: Unexpected response type.");
        }

        setDisabled(false);
        setLoading(false);



      } else {
        console.error("Error: No valid files returned.");
        setLoading(false);
      }
    } catch (error) {
      console.error("API Request failed: ", error);
      setLoading(false);
    }
  };


  function parseEndpoints(pyString) {
    const routePattern = /@app\.route\(["']([^"']+)["'](,.*)?\)/g;

    const endpoints = {};

    let match;
    while ((match = routePattern.exec(pyString)) !== null) {
        const [fullMatch, url, extraArgs] = match;

        let methods = ['GET'];
        if (extraArgs) {
            const methodsMatch = extraArgs.match(/methods\s*=\s*\[([^\]]+)\]/);
            if (methodsMatch) {
                methods = methodsMatch[1].replace(/["'\s]/g, '').split(',');
            }
        }

        endpoints[url] = methods;
    }

    return endpoints;
}

const EndpointsDisplay = ({ endpoints }) => {
  return (
      <div className="p-6 bg-white rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-4 text-gray-800">Parsed API Endpoints</h2>
          <ul className="space-y-2">
              {Object.entries(endpoints).map(([route, methods]) => (
                  <li
                      key={route}
                      className="flex items-center justify-between p-3 bg-gray-100 rounded-md shadow-sm hover:bg-gray-200"
                  >
                      <span className="font-semibold text-blue-600">{route}</span>
                      <span className="text-gray-700">{methods.join(', ')}</span>
                  </li>
              ))}
          </ul>
      </div>
  );
};

  return (
    <>
  <div className="min-h-screen flex flex-col p-4">
    <div className="flex-1 pb-6 ">

    <div className='flex flex-row justify-between pb-2'>
      <div className='flex flex-row'>
             <img src="/Code.gif" className="h-10 p-1"alt=""></img>
      <p className=' text-white text-xl pt-2'>SoftwareNow</p>
      </div>

      {/* <div className='pr-1 flex flex-row  '>
        <p className='text-white text-sm pr-1'>Powered By </p>
        <img alt='wer' src='/gemini.png' className='h-4'></img>
      </div> */}
    </div>
{/* <video className='h-14' playsInline >
      <source src={videoball} type="video/mp4"/>
     </video>    */}
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
    

      <div className='backdrop-blur-sm 
        bg-zinc-900	 border rounded-xl border-zinc-800  
          sm:col-span-2 p-4'>   
          <div className=' pb-4  '>
                        <Badge color='blue'> Request </Badge>
                        </div>  
  <Field className=' mx-auto max-w-xl  text-center'>
      {/* <Label>Full name</Label> */}
      <Input 
      name="user_prompt" 
      // className="purple-input focus:border-transparent "
      placeholder="Type your software request here"

      value={userPrompt} 
      onChange={handlePromptChange} />
     
   
              <Button type='submit' onClick={handleGenerate} disabled={loading} className="text-green-400 mt-4" outline>
              {loading? 
                             <span  className='flex'>
                            <Lottie
                           
                            loop={true}
                            animationData={stars} 
                      
                            style={{ height: 30 }} // Adjust the size to fit your needs
                          />
                          Loading
                          </span> 
                          :
                          <>
                <span>
                {/* <HiOutlineSparkles className='size-5 text-yellow-400' /> */}

                  <img alt="*" className='h-6' src="/Sparkle.gif"></img>
                </span> 
                Generate</>
              }
                </Button>

     
    </Field>   




      </div>
   <div className='bg-zinc-900	 border rounded-xl border-zinc-800 h-[600px] 
                      backdrop-blur-sm    grid grid-cols-3 p-4 gap-x-2 grid-rows-12 '>
                        <div className='col-span-3 row-span-1  '>
                        <Badge color='green'> Code </Badge>
                        </div>

                        {loading? 
                            <div className='row-span-10 m-auto col-span-3'>
                            <Lottie
                            
                            loop={true}
                            animationData={codeAnimation} 
                      
                            style={{ width: 300, height: 300 }} // Adjust the size to fit your needs
                          />
                          <p className='text-white text-center'>wait one moment</p>
                            </div>
                          :
<>
                          <div className=' row-span-10  rounded-bl-xl rounded-tl-xl 
                          rounded-tr-xl  col-span-1 
                           overflow-x-auto bg-[#0f0f0f]/70 mb-10'>
                            
                        
                        
<div className="input gap-y-1">
{
  requestType === "FrontEnd" ? (
  <>
      <>
        <button onClick={() => handleFileSwitch('style.css')} className="value">
          <img src="/css.png" alt="CSS" className='h-4'/>
          style.css
        </button>
        <button onClick={() => handleFileSwitch('index.html')} className="value">
          <img src="/html-5.png" alt="HTML" className='h-4'/>
          index.html
        </button>
        <button onClick={() => handleFileSwitch('script.js')} className="value">
          <img src="/js.png" alt="JS" className='h-4'/>
          script.js
        </button>
      </>
  </>
) : requestType === "BackEnd" ?  (
  <>
    <button onClick={() => handleFileSwitch('main.py')} className="value">
      <img src="/python.png" alt="Python" className='h-4'/>
      main.py
    </button>
    <button onClick={() => handleFileSwitch('requirements.txt')} className="value">
      <img src="/pip.png" alt="PIP" className='h-4'/>
      requirements.txt
    </button>
  </>
)
:

backEndRequest && frontEndRequest ? (
        <>
          {/* Front-End Section */}
          <div className='p-2 rounded-t-xl' style={{ backgroundColor: '#171717', boxShadow: 'inset 2px 5px 10px rgb(5, 5, 5)' }}>
            <p className='text-[#d3d3d3] font-semibold text-xs'>Front End</p>
          </div>
          <div>
            <button onClick={() => handleFileSwitch('style.css')} className="value">
              <img src="/css.png" alt="CSS" className='h-4'/>
              style.css
            </button>
            <button className="value" onClick={() => handleFileSwitch('index.html')}>
              <img src="/html-5.png" alt="HTML" className='h-4'/>
              index.html
            </button>
            <button className="value" onClick={() => handleFileSwitch('script.js')}>
              <img src="/js.png" alt="JS" className='h-4'/>
              script.js
            </button>
          </div>

          {/* Back-End Section */}
          <div className='p-2 rounded-t-xl' style={{ backgroundColor: '#171717', boxShadow: 'inset 2px 5px 10px rgb(5, 5, 5)' }}>
            <p className='text-[#d3d3d3] font-semibold text-xs'>Back End</p>
          </div>
          <div>
            <button onClick={() => handleFileSwitch('main.py')} className="value">
              <img src="/python.png" alt="Python" className='h-4'/>
              main.py
            </button>
            <button className="value" onClick={() => handleFileSwitch('requirements.txt')}>
              <img src="/pip.png" alt="PIP" className='h-4'/>
              requirements.txt
            </button>
          </div>
        </>
      ) : (
        <></>
      )}


</div>

                            
                          </div>





                          <div className='row-span-10 bg-zinc-800 rounded-tr-xl rounded-br-xl col-span-2
                           overflow-auto mb-10'>
                           
                             <Editor
                height="100%"
                theme="vs-dark"
                language={selectedFile.endsWith('.js') ? "javascript" 
                : selectedFile.endsWith('.css') ? "css" 
                : selectedFile.endsWith('.py') ? "python"
                : selectedFile.endsWith('.txt') ? "plaintext"
                : "html"}
 // className='m-1 '
                // defaultLanguage="html"
                // value={code}
                value={files[selectedFile]}

                onChange={handleEditorChange}
            /> 
                         


                          </div>
</>
}




                          <div className='col-span-3 row-span-1  '>
                          <Button  onClick={handleSave} disabled={disabled} outline>
                            <span><FiDownload  /></span>
                            Download Code</Button>         
 
                                         </div>
                    
       </div>
      <div className='bg-zinc-900	 border rounded-xl border-zinc-800 h-[600px] 
                     backdrop-blur-sm  p-4 grid grid-cols-1 grid-rows-12 '>      
                      <div className='  row-span-1 '>
                        <Badge color='red'> Output </Badge>
                        </div> 



                        {loading? 
                            <div className='row-span-10 m-auto col-span-3'>
                            <Lottie
                            
                            loop={true}
                            animationData={creditCard} 
                      
                            style={{ width: 300, height: 300 }} // Adjust the size to fit your needs
                          />
                          </div>
:
                        <div id="window"  > 
                              <div className="tools h-8 bg-black/15 w-full rounded-t-xl">
    <div className="circle">
      <span className="red box"></span>
    </div>
    <div className="circle">
      <span className="yellow box"></span>
    </div>
    <div className="circle">
      <span className="green box"></span>
    </div>
  </div>

  {
  requestType === "FrontEnd" ? (
  <>
  <iframe 
                // srcDoc={code} 
                className='rounded-b-xl shadow-lg '
                srcDoc={iframeContent} 
                // srcDoc={files["index.html"]} 
                sandbox="allow-scripts"
                title="output"
                style={{ width: '100%', 
                height: '400px', 
                border: 'none', 
                background: 'white',
                // background: 'rgba(0, 0, 0, 0.5)',
              
                // background: 'linear-gradient(0deg, #000, #272727)'
              }} 
            />


  </>
  ) : requestType === "BackEnd" ? (
  <>
    <div className='row-span-10 col-span-3'>
    <EndpointsDisplay endpoints={sampleEndpoints} />

  </div>
  </>
  
  ): <>
  <div className='bg-black/40 row-span-10 h-[400px] rounded-b-xl'>

  </div>
  
  
  
  </>}


          </div>
                        
                        }    
                       

      </div>
   
 
 

    </div>
    </div>

   <div className='flex flex-row w-full justify-between mt-auto   	'>
     <div className=' flex flex-row  py-1  '>
        <p className='text-white text-sm pr-1'>Powered By </p>
        <img alt='wer' src='/gemini.png' className='h-4'></img>
      </div>
     <div className='  flex flex-row   '>
        <p className='text-white text-sm pr-1'> </p>
        <a 
        className='bg-zinc-900 rounded-xl px-2 py-1 items-center

         text-white text-sm hover:text-blue-500 flex flex-row'
        href="https://linkedin.com/in/brianabedi">
          <span><img className="h-4 pr-2"alt="LI" src="/linkedin.png"></img></span>
          Brian Abedi</a>
        {/* <img alt='wer' src='/gemini.png' className='h-4'></img> */}
      </div>
      </div>
      </div>
    </>
  );
}

export default App;
